import Layout from "../containers/Layout";
import React from "react";
import {graphql, HeadFC, Link} from "gatsby";
import Seo from "../containers/Seo";

function CompletePage() {
  return (
    <Layout>
      <main>
        <div className="hero-unit">
          <h1>申し込み完了</h1>

          <div className="zag"/>
        </div>

        <div className="warning-bg">
          <p>
            査定申し込みいただきありがとうございました。<br />
            査定結果は24時間以内にご指定のメールアドレスへ連絡いたします。<br />
            しばらくお待ちくださいますようお願いいたします。
          </p>

          <div className="text-center">
            <Link to={"/"}>ホームへ戻る</Link>
          </div>
        </div>

      </main>
    </Layout>
  );
}
export const query = graphql`
  query CompleteQuery {
    site {
      siteMetadata {
        title
        description
        twitter
        siteUrl
      }
    }
  }
`

export const Head: HeadFC = ({data}: {data: any}) => (
  <Seo data={data}>
    <title id="title">査定申し込み完了 | {data.site.siteMetadata.title}</title>
  </Seo>
);


export default CompletePage;